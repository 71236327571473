import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import backgroundSound from '@/assets/sounds/background.mp3'
import icecrackoneSound from '@/assets/sounds/icecrackone.mp3'
import icecracktwoSound from '@/assets/sounds/icecracktwo.mp3'
import icecrackthreeSound from '@/assets/sounds/icecrackthree.mp3'
import icecrackfourSound from '@/assets/sounds/icecrackfour.mp3'
import snowswipeoneSound from '@/assets/sounds/snowswipeone.mp3'
import snowswipetwoSound from '@/assets/sounds/snowswipetwo.mp3'

export const store = new Vuex.Store({
  state: {
    session: null,
    language: 'en',
    termsOverlay: false,
    country: null,
    glassColor: 'green',
    sounds: {
      backgroundSound: new Audio(backgroundSound),
      icecrackoneSound: new Audio(icecrackoneSound),
      icecracktwoSound: new Audio(icecracktwoSound),
      icecrackthreeSound: new Audio(icecrackthreeSound),
      icecrackfourSound: new Audio(icecrackfourSound),
      snowswipeoneSound: new Audio(snowswipeoneSound),
      snowswipetwoSound: new Audio(snowswipetwoSound),
    },
    gameFinished: false
  },
  mutations: {
    setGameFinished (state, val) {
      state.gameFinished = val
    },
    setLanguage (state, val) {
      state.language = val
    },
    setCountry (state, val) {
      state.country = val
    },
    setSession (state, session) {
      state.session = session
    },
    setGlassColor (state, val) {
      state.glassColor = val
    },
    setTermsOverlay (state, val) {
      state.termsOverlay = val
    }
  }
})

export default store
