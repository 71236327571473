<template>
  <div class="finish-wrapper viewport is-fullscreen">
    <SnowEffect />
    <div class="abs-7 center">
      <img src="@/assets/images/logo_jaegermeister.png" class="logo">
    </div>
    <div v-show="!shotRedeemed" class="stacked-viewport abs-14 center mt-5">
      <div ref="countdown" class="countdown abs-9 shiny up">
        <div class="countdown-back"><span class="opacity-0">-</span>88:88</div>
        <div>-{{ parsedCountdown(minutes) }}:{{ parsedCountdown(seconds) }}</div>
      </div>
      <div ref="order_now" class="order-now my-4 abs-3 small shiny up bold">Order your ice cold shot now</div>
    </div>

    <div v-show="shotRedeemed" class="abs-14 center pt-5">
      <div class="medium bold wide up">the secret is</div>
      <div class="big bold wide up">ice cold</div>
    </div>

    <div class="flex center shot-box">
      <img src="@/assets/images/shot_without.png" class="shot-image">
      <a v-if="shotRedeemed" href="https://instagram.com/jagermeister" ref="instagram" target="_blank" class="instagram"><img src="@/assets/images/instagram.svg" /></a>
    </div>

    <div class="abs-20 pt-5 center">
      <div ref="secret">
        <div class="medium bold wide up">the secret is</div>
        <div class="big bold wide up">ice cold</div>
      </div>

      <div ref="responsibly" class="tiny wide up bottom">
        <img class="responsibly-claim" src='@/assets/images/drink-responsibly.svg' />
      </div>

      <div ref="slider" class="center" v-show="!showRedeem && !shotRedeemed && !timesUp">
        <div class="medium bold wide up mb-5 white">Get your free shot at the bar</div>
        <Slider
          knobColor='#DD5A12'
          arrowColor='#E9E3C7'
          labelText="push to redeem"
          @unlocked="sliderUnlocked"
        />
      </div>

      <div v-show="shotRedeemed">
        <div class="medium bold up mb-5 thank-you">
          Thanks for playing!
        </div>
        <div class="small mb-5">
          Enjoy your free
          <span class="orange bold">ice cold shot</span>.
        </div>
        <div class="tiny wide up mt-5">
          <img class="responsibly-claim" src='@/assets/images/drink-responsibly.svg' />
        </div>
      </div>

      <div v-show="timesUp">
        <div class="medium bold up mb-5">
          Time's up!
        </div>
        <div class="small">
          Better hurry next time and get your shot
          <span class="orange bold">ice cold</span>.
        </div>
        <div class="tiny wide up bottom mt-5">
          <img class="responsibly-claim" src='@/assets/images/drink-responsibly.svg' />
        </div>
      </div>

      <img class="fog" src="@/assets/images/fog.png" v-show="animationStarted"/>

    </div>

    <transition name="bounce">
      <div v-show="showRedeem && !timesUp" class="redeem-modal">
        <h2>Did you get your ice cold shot?</h2>
        <div>
          <div class="btn" @click="showRedeem = false">Not yet</div>
          <div class="btn" @click="redeemShot">Got it!</div>
        </div>
        <strong>CAUTION:</strong>
        By accepting you instantly redeem your free shot for today.
      </div>
    </transition>

  </div>
</template>

<script>
import Slider from '@/components/Slider'
import updateSession from '@/mixins/updateSession'
import SnowEffect from '@/components/SnowEffect'

export default {
  components: { Slider, SnowEffect },
  mixins: [ updateSession ],
  props: {
    animationStarted: Boolean
  },
  data () {
    return {
      countdown: null,
      seconds: 0,
      minutes: 18,
      // seconds: 4,
      // minutes: 0,
      countdownStarted: false,
      timesUp: false,
      showRedeem: false,
      shotRedeemed: false
    }
  },
  mounted () {
    console.log("Finish layer mounted")
    this.resetAnimation()
  },
  destroyed () {
    this.stopCountdown()
  },
  methods: {
    resetAnimation() {
      this.$refs.countdown.style.opacity = 0
      this.$refs.order_now.style.opacity = 0
      this.$refs.secret.style.opacity = 0
      this.$refs.responsibly.style.opacity = 0
      this.$refs.slider.style.opacity = 0
      this.$refs.slider.style.display = 'none'
    },
    playAgain() {
      window.location.reload()
    },
    startAnimation () {
      const _this = this

      this
        .$anime
        .timeline({
          autoplay: true,
          duration: 1000,
          easing: 'linear',
          update: (anim) => {
            if (anim.currentTime >= 3000) {
              _this.$refs.secret.style.display = 'block'
            }
            if (anim.currentTime >= 7000) {
              _this.$refs.secret.style.display = 'none'
              _this.$refs.slider.style.display = 'block'
            }
          },
          complete: () => {
            _this.startCountdown()
          }
        })
        .add({
          targets: _this.$refs.countdown,
          opacity: 1,
          duration: 2000
        })
        .add({
          targets: _this.$refs.order_now,
          opacity: 1,
        }, 1000)
        .add({
          targets: [_this.$refs.secret, _this.$refs.responsibly],
          opacity: 1,
        }, "+=1000")
        .add({
          targets: [_this.$refs.secret, _this.$refs.responsibly],
          opacity: 0,
        }, '+=2000')
        .add({
          targets: _this.$refs.slider,
          opacity: 1,
        })
    },
    startCountdown () {
      this.countdown = setInterval(this.countTimerDown, 1000)
    },
    stopCountdown() {
      clearInterval(this.countdown)
    },
    countTimerDown () {
      if (this.seconds <= 0) {
        this.seconds = 60
        this.minutes -= 1
      }
      this.seconds -= 1

      if (this.minutes < 1) {
        this.$refs.countdown.classList.add('shiny--alert')
        this.$refs.order_now.classList.add('shiny--alert')
      }

      if (this.minutes === 0 && this.seconds === 0) {
        this.timesUp = true
        this.stopCountdown()
      }
    },
    parsedCountdown (value) {
      if (value < 10) {
        return `0${value}`
      }
      return value
    },
    sliderUnlocked () {
      this.showRedeem = true
    },
    redeemShot () {
      this.showRedeem = false
      this.shotRedeemed = true

      this.stopCountdown()
      this.markSessionAsFinished()
      this.$store.commit('setGameFinished', true)
    }
  },
  watch: {
    animationStarted () {
      this.startAnimation()
    }
  }
}
</script>

<style lang="sass" scoped>
.finish-wrapper
  z-index: 100
  background-image: url("~@/assets/images/bg-age-check.svg")
  background-color: black
  background-repeat: no-repeat
  background-position: right center
  background-attachment: fixed
  background-size: cover
  overflow: hidden

.center
  text-align: center

.countdown
  position: relative
  font-family: "Calculator"
  font-size: 11rem
  margin-left: -4rem
  line-height: 1

  .countdown-back
    position: absolute
    opacity: 0.35
    left: 50%
    transform: translateX(-50%)
  
.order-now
  font-size: 1.9rem !important
  line-height: 1
  display: flex
  justify-content: center
  align-items: center

.shiny
  text-shadow: 0px 0px 15px #5CED6E
  color: white
  animation: flickerShadowGreen 10s 3s linear infinite

  &.shiny--alert
    text-shadow: 0px 0px 15px #DD5A12
    animation: flickerShadowRed 10s linear infinite

@keyframes flickerShadowGreen
  0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% 
    text-shadow: 0px 0px 15px #5CED6E
  20%, 21.999%, 63%, 63.999%, 65%, 69.999%
    text-shadow: none

@keyframes flickerShadowRed
  0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% 
    text-shadow: 0px 0px 15px #DD5A12
  20%, 21.999%, 63%, 63.999%, 65%, 69.999%
    text-shadow: none

.up
  text-transform: uppercase

.bold
  font-weight: bold

.wide
  letter-spacing: 0.3rem

.small
  font-size: 1.8rem

.medium
  font-size: 3rem

.big
  font-size: 6rem

.bottom
  position: absolute
  bottom: 0
  left: 0
  right: 0
  text-align: center
  padding: 2rem

.redeem-modal
  position: absolute
  background: linear-gradient(180deg, #193B01 13.02%, #040703 100%)
  box-shadow: 0px -5px 30px #101C0E
  border-radius: 20px
  padding: 5rem 3rem
  top: 25rem
  left: 0
  margin: 0 2rem
  text-align: center
  border: 2px solid
  user-select: none
  z-index: 10

  h2
    font-size: 3rem
    text-transform: uppercase
    margin-bottom: 2rem

  .btn
    font-size: 2rem
    border: 2px solid
    padding: 2rem 1rem
    display: inline-block
    margin: 2rem 0.5rem
    width: 45%
    text-transform: uppercase
    font-weight: bold
    border-radius: 10px


    + .btn
      background-color: var(--color-orange)
      border-color: var(--color-orange)

    &:active
      opacity: 0.9

.bounce-enter-active
  animation: bounce-in .3s

.bounce-leave-active
  animation: bounce-in .3s reverse

.responsibly-claim
  height: 1rem !important

.shot-box
  position: relative

  .instagram
    position: absolute
    bottom: 0
    right: 2rem
    width: 10rem
    height: 10rem
    animation: bounce-in 1.5s

@keyframes bounce-in
  0%
    transform: scale(0)
  50%
    transform: scale(1.2)
  100%
    transform: scale(1)

.fog
  z-index: 9
  position: absolute
  left: 0
  bottom: -10rem
  animation: slideFog 100s 1s linear infinite
  will-change: transform
  transform: translateX(-100%)
  pointer-events: none

.play_again
  text-transform: uppercase
  margin-top: 20px
  font-size: 20px

@keyframes slideFog
  to
    transform: translateX(100%)

.thank-you
  margin-top: 40px

.shot-image
  margin: 0 auto
  display: block
  min-height: 100% 
</style>

