import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const messages = {
  en: {
    age_check: {
      intro: 'To access our Jägermeister websites you must be of legal drinking age in your country of access or older.',
      intro_confirmed_age: 'Slide up to enter the game',
      enter_birthday: 'Please enter your date of birth:',
      day: 'Day',
      month: 'Month',
      year: 'Year',
      day_placeholder: 'DD',
      month_placeholder: 'MM',
      year_placeholder: 'YYYY',
      failed: 'We\'re sorry! But you must have reached the legal drinking age to participate in this game.',
      error_day_message: 'Please enter a valid day',
      error_month_message: 'Please enter a valid month',
      error_year_message: 'Please enter a valid year'
    },
    game_finished: {
      the_game_is_finished_1: 'Thank you for playing',
      the_game_is_finished_2: 'THE SECRET IS ICE COLD'
    }
  },
  de: {
    age_check: {
      intro: 'Um unsere Jägermeister Websites besuchen zu können musst du mindestens 18 Jahre alt sein.',
      intro_confirmed_age: 'Nach oben wischen um das Spiel zu betreten',
      enter_birthday: 'Bitte gib Dein Geburtsdatum ein:',
      day: 'Tag',
      month: 'Monat',
      year: 'Jahr',
      day_placeholder: 'TT',
      month_placeholder: 'MM',
      year_placeholder: 'JJJJ',
      failed: 'Es tut uns leid! Aber Du musst das gesetzliche Mindestalter erreicht haben, um an diesem Spiel teilnehmen zu können.',
      error_day_message: 'Bitte gib einen gültigen Tag ein',
      error_month_message: 'Bitte gib einen gültigen Monat ein',
      error_year_message: 'Bitte gib ein gültiges Jahr ein'
    },
    game_finished: {
      the_game_is_finished_1: 'Danke dass du gespielt hast',
      the_game_is_finished_2: 'THE SECRET IS ICE COLD'
    }
  }
}

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'de',
  messages
})

export default i18n
