<template>
  <div id="app">
    <GameFinished
      v-if="gameFinished"
    />
    <span v-else>
      <AgeCheck
        v-if="step === 'ageCheck'"
        @ageValidated="step = 'scratch'; playBackgroundSound()"
        @debug="debug()"
      />
      <ScratchArea
        v-if="step === 'scratch'"
        getPercentageCleared
        @percentage-update="updateScratchedPercentage"
      >
      </ScratchArea>
      <!-- mount together with ScratchArea -->
      <IceLayer
        :snow-washed-away="snowWashedAway"
        @iceCompletelyCrushed="startFinishAnimation = true"
        v-if="!startFinishAnimation"
      />
      <Finish
        :animation-started="startFinishAnimation"
        @finishGame="step = 'gameFinished'"
      />
    </span>
  </div>
</template>

<script>
import GameFinished from '@/components/GameFinished'
import AgeCheck from '@/components/AgeCheck'
import IceLayer from '@/components/IceLayer'
import ScratchArea from '@/components/ScratchArea'
import Finish from '@/components/Finish'
import createSession from '@/mixins/createSession'
import i18n from '@/plugins/i18n'

export default {
  mixins: [ createSession ],
  components: {
    GameFinished,
    AgeCheck,
    ScratchArea,
    IceLayer,
    Finish,
  },
  computed: {
    gameFinished () {
      // activate if you want to test the cheating mechanism
      // return this.$store.state.gameFinished
      return false
    }
  },
  data() {
    return {
      step: 'ageCheck',
      startFinishAnimation: false,
      snowWashedAway: false,
    }
  },
  mounted () {
    // check for language compaign and country query
    let lang = new URL(location.href).searchParams.get('lang')
    let country = new URL(location.href).searchParams.get('country')
    let campaignId = new URL(location.href).searchParams.get('campaignId')

    if (lang) {
      i18n.locale = lang
      this.$store.commit('setLanguage', lang)
    }

    if (country) {
      this.$store.commit('setCountry', country)
    }

    // set white glass color if in DACH region (language German)
    if (lang === 'de') {
      this.$store.commit('setGlassColor', 'white')
    }

    // set session
    this.getFingerprintAndInitializeSession(campaignId)
  },
  methods: {
    updateScratchedPercentage(percentage) {
      if (percentage > 90) {
        this.step = 'ice'
        this.snowWashedAway = true
      }
    },
    debug () {
      this.step = 'ice'
      this.snowWashedAway = true
      this.startFinishAnimation = true
    },
    playBackgroundSound () {
      this.backgroundSound.volume = 0.3
      this.backgroundSound.loop = true
      this.backgroundSound.play()
    }
  },
};
</script>

<style lang="sass" scoped>
h3
  color: #2c3e50
  text-align: center

a
  color: #2196f3

</style>
