<template>
  <div class="viewport">
    <div align="center">
      <img src="@/assets/images/logo_jaegermeister.png" class="logo" />
    </div>
    <h1 class="first-text">{{ $t('game_finished.the_game_is_finished_1') }}</h1>
    <h2 class="second-text">{{ $t('game_finished.the_game_is_finished_2') }}</h2>
    <div class="flex py-5 center">
      <img class="international-bottle" src="@/assets/images/international_bottle.png">
    </div>
  </div>
</template>

<script>

export default {
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/main.sass"
.viewport
  background-image: url("~@/assets/images/bg-age-check.svg")
  background-color: black
  background-repeat: no-repeat
  background-position: right center
  background-attachment: fixed
  background-size: cover

.logo
  height: 10rem
  margin: 2rem 0

.first-text
  text-align: center

.second-text
  margin: 20px 0 10px 0
  text-align: center
  font-size: 4rem

</style>
