import updateSession from '@/mixins/updateSession'
export default {
  mixins: [ updateSession ],
  computed: {
    backgroundSound () {
      return this.$store.state.sounds.backgroundSound
    },
    icecrackoneSound () {
      return this.$store.state.sounds.icecrackoneSound
    },
    icecracktwoSound () {
      return this.$store.state.sounds.icecracktwoSound
    },
    icecrackthreeSound () {
      return this.$store.state.sounds.icecrackthreeSound
    },
    icecrackfourSound () {
      return this.$store.state.sounds.icecrackfourSound
    },
    snowswipeoneSound () {
      return this.$store.state.sounds.snowswipeoneSound
    },
    snowswipetwoSound () {
      return this.$store.state.sounds.snowswipetwoSound
    },
  }
}
